@import url("https://fonts.googleapis.com/css?family=Montserrat:500,600,700&display=swap");

iframe {
    margin: 0;
    box-sizing: border-box;
    overflow: hidden;
}

body {
    margin: 0;
    font-family: "Montserrat" !important;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    box-sizing: border-box;
    background-color: #d4d4ce;
}

body,
h1,
h2,
h3,
h4,
h5,
h6,
p,
a,
button,
input[type="button"],
input[type="date"],
input[type="submit"],
input[type="reset"],
input[type="text"],
input[type="number"],
label,
ul,
ol,
li,
blockquote,
table,
th,
td,
caption,
code,
pre,
::placeholder,
/* Add more HTML elements here */
abbr,
acronym,
address,
article,
aside,
audio,
b,
bdi,
bdo,
big,
canvas,
caption,
cite,
code,
col,
colgroup,
datalist,
dd,
del,
details,
dfn,
dialog,
div,
dl,
dt,
em,
embed,
fieldset,
figcaption,
figure,
footer,
form,
header,
hr,
i,
iframe,
img,
ins,
kbd,
legend,
main,
map,
mark,
meter,
nav,
noscript,
object,
optgroup,
option,
output,
picture,
q,
rp,
rt,
ruby,
s,
samp,
section,
select,
small,
span,
strong,
sub,
summary,
sup,
svg,
template,
textarea,
time,
u,
var,
video,
wbr {
    font-family: "Montserrat";
}
::after {
    font-family: "Montserrat";
}
::before {
    font-family: "Montserrat";
}
::content {
    font-family: "Montserrat";
}
:focus {
    outline: none;
}
